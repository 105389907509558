@import 'src/styles/colors.scss';
@import 'src/styles/fonts.scss';

.key {
  order: solid 1px $borderColor;
  background-color: $borderColor;
  padding: 15px;
  border-radius: 12px;
  font-weight: $weightIntermediate;
  text-align: center;
}
