@import 'src/styles/colors.scss';

.AccountWrapper {
  position: absolute;
  right: 15px;
  left: 15px;
  bottom: 15px;
  top: 15px;
  overflow-x: auto;
  background-color: $backgroundColor;
  box-shadow: 0px 0px 10px $primaryColorLighter;
  border-radius: 15px;
  padding: 5px;
}
