@import 'src/styles/colors.scss';
@import 'src/styles/fonts.scss';

.backToLoginLink {
  margin-top: 33px;
  text-decoration: none;
  font-weight: $weightIntermediate;
  font-size: $fontText;
  line-height: 16px;
  text-align: center;
  color: $linkColor;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
