@import 'src/styles/colors.scss';
@import 'src/styles/fonts.scss';

.bannerWrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.04);
  padding: 30px;
  background-color: $backgroundColor;

  .banner {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .bannerText {
      display: flex;
      flex-direction: column;
      font-size: $fontSubtitle;
      font-weight: $weightBold;
      line-height: 18.15px;

      span {
        font-weight: $weightRegular;
        margin-top: 5px;
      }
      .bannerError{
        position: absolute;
        bottom: 15px;
        right: 10px;
      }
    }

    button {
      margin: 0px;
      font-weight: $weightBold;
    }
  }
}
