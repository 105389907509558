@import 'src/styles/colors.scss';
@import 'src/styles/fonts.scss';

.teamRowWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr min-content;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $borderColor;
  padding: 10px 0;
  font-size: $fontText;
  max-width: 600px;
  gap: 20px;

  &:last-child {
    border-bottom: 0;
  }

  .badgeAndName {
    align-items: center;
    display: grid;
    grid-template-columns: min-content 1fr;
  }

  .emailAndRole {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    :last-child {
      text-align: right;
    }
  }

  .icons {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
