@import 'src/styles/colors.scss';
@import 'src/styles/fonts.scss';

.BrandingWrapper {
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;

  .previewCardWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas:
      'cover cover logo layout'
      'cover cover colors fonts';
    @media only screen and (max-width: 1550px) {
      grid-template-areas:
        'cover cover cover cover'
        'cover cover cover cover'
        'logo logo layout layout'
        'colors colors fonts fonts';
    }
    @media only screen and (max-width: 700px) {
      grid-template-areas:
        'cover cover cover cover'
        'cover cover cover cover'
        'logo logo logo logo'
        'layout layout layout layout'
        'colors colors fonts fonts';
    }
    @supports (gap: 15px) {
      padding: 15px;
      gap: 15px;
    }
    @supports not (gap: 15px) {
      padding: 10px;
      > div {
        margin: 10px;
      }
    }
    .cover-wrapper {
      grid-area: cover;
    }
    .logo-wrapper {
      grid-area: logo;
    }
    .layout-wrapper {
      grid-area: layout;
    }
    .colors-wrapper {
      grid-area: colors;
    }
    .fonts-wrapper {
      grid-area: fonts;
    }
  }
}
