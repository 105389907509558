@import 'src/styles/colors.scss';
@import 'src/styles/fonts.scss';

.sliderContainer {
  width: 100%;
  display: 'flex';
  flex-direction: 'column';
  margin-bottom: 20px;

  .sliderLabel {
    margin-right: 5px;
    font-size: $fontText;
  }
  .slider {
    margin-top: 10px;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 5px;
    width: 100%;
    height: 10px;
    border: 1px solid $borderColor;
    background: $backgroundColor;
    outline: none;
    opacity: 0.9;
    -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
    transition: opacity 0.2s;
  }

  .slider:hover {
    opacity: 1;
  }

  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background: $primaryColor;
    cursor: pointer;
  }

  .slider::-moz-range-thumb {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background: $primaryColor;
    cursor: pointer;
  }

  .rangeValue {
    margin-left: 10px;
    font-size: $fontTiny;
    font-weight: bold;
  }
}
