@import 'src/styles/colors.scss';
@import 'src/styles/fonts.scss';

$modalWidth: 464px;
$modalMaxHeight: 75vh;

.modalOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $lightGreyColor;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11; // 1 more then navBar

  .modal {
    position: relative;
    overflow: auto;
    width: $modalWidth;
    background: $backgroundColor;
    border-radius: 25px;
    padding: 0 20px 20px;
    height: fit-content;
    max-height: $modalMaxHeight;

    .modalHeader {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      background-color: $backgroundColor;
      display: flex;
      justify-content: space-between;
      padding: 20px 0;

      .modalHeaderTitle {
        font-size: $fontSubtitle;
        font-weight: $weightBold;
      }

      .modalHeaderClose {
        font-size: $fontText;
        cursor: pointer;
      }
    }

    .modalButtons {
      float: right;
      display: flex;
      width: 320px;
      justify-content: space-between;
    }
  }
}
