@import 'src/styles/colors.scss';
@import 'src/styles/fonts.scss';

.loginLinks {
  font-size: $fontText;
  line-height: 16px;
  display: flex;

  .loginLink {
    text-decoration: none;
    color: $primaryColor;
  }

  .newAccountLink {
    margin: 0 10px;
    color: $linkColor;
    font-weight: $weightIntermediate;
  }
}
