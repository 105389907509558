.wrapper {
  background-image: url('../../images/background.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 100vh;
  width: 100vw;
  padding: 20px;
}
