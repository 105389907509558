$backgroundColor: rgb(255, 255, 255);
$borderColor: rgba(229, 229, 229, 0.5);
$greenColor: rgb(46, 170, 46);
$lightGreyColor: rgba(17, 17, 17, 0.5);
$lightTextColor: rgb(255, 255, 255);
$linkColor: rgb(0, 186, 255);
$orderCancelledColor: rgb(191, 36, 81);
$orderPendingColor: rgb(246, 134, 87);
$orderConfirmedColor: rgb(246, 179, 82);
$orderCompletedColor: rgb(46, 170, 46);
$primaryColor: rgb(17, 17, 17);
$primaryColorLighter: rgba(0, 0, 0, 0.1);
$subtitleColor: rgb(187, 187, 187);
$templateColor: rgb(210, 210, 210);
$warningColor: rgb(244, 63, 63);
