@import 'src/styles/colors.scss';
@import 'src/styles/fonts.scss';

.DropZoneWrapper {
  border: 1px dashed $primaryColor;
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  padding: 5px;
  margin: 20px;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .dropArea {
    width: 90%;
    height: 182px;

    //  uncomment and delete style above once SPINE_LOGO is implemented in the BE
    // width: 75px;
    // height: 75px;
    // flex-direction: column;

    &:focus-within,
    &:focus {
      background-color: $borderColor;
    }
  }

  svg {
    width: 15px;
    height: 13px;
  }
}
