@import 'src/styles/colors.scss';
@import 'src/styles/fonts.scss';

.accountCardWrapper {
  background-color: $backgroundColor;
  background-color: $backgroundColor;
  border-bottom: 1px solid $borderColor;
  padding: 25px;
  font-size: $fontText;

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }

  .accountCardHeader {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    .generalDescription {
      margin-bottom: 35px;

      .accountCardTitle {
        font-size: $fontSubtitle;
        font-weight: $weightBold;
        margin-bottom: 13px;
      }
    }

    .editIcon {
      cursor: pointer;
      display: flex;

      .text {
        margin-left: 5px;
        font-weight: 600;
      }
    }
  }
  button {
    color: $linkColor;
  }
}
