@import 'src/styles/colors.scss';
@import 'src/styles/fonts.scss';

.accountCardRow {
  display: flex;
  width: 450px;

  .accountUserInfo {
    width: 50%;
    max-width: 300px;
    margin: 0 50px 20px 0;
    display: flex;
    flex-direction: column;
    font-size: $fontText;
    line-height: 16px;

    .accountLabel {
      font-weight: $weightBold;
      margin-bottom: 13px;
    }

    .accountValue {
      color: $lightGreyColor;
      border: 1px solid $borderColor;
      box-sizing: border-box;
      border-radius: 15px;
      padding: 10px;
    }
  }
  button {
    color: $linkColor;
  }
}

