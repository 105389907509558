@import 'src/styles/colors.scss';

.radioWrapper {
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    z-index: 10;
  }

  input:checked ~ .styledRadio {
    border: solid 1px $primaryColor;
  }
  .styledRadio {
    position: relative;
    display: block;
    height: 16px;
    width: 16px;
    border: solid 1px $primaryColorLighter;
    background-color: $backgroundColor;
    transition: 300ms;
    border-radius: 50%;
  }
}
