@import 'src/styles/colors.scss';
@import 'src/styles/fonts.scss';

.templatesWrapper {
  position: absolute;
  right: 15px;
  left: 15px;
  bottom: 15px;
  top: 15px;
  overflow-x: auto;
  border-radius: 15px;
  background-color: $backgroundColor;
  box-shadow: 0px 0px 10px $primaryColorLighter;
  padding: 15px;

  .templatesTitle {
    color: $primaryColor;
    font-size: $fontSubtitle;
    font-weight: 700;
    line-height: 18px;
    padding-bottom: 25px;
  }
  .templatesInner {
    display: block;
  }
  .specsBox {
    display: flex;
    justify-content: space-between;
    border: 1px solid $borderColor;
    box-sizing: border-box;
    border-radius: 15px;
    font-weight: bold;
    font-size: $fontText;
    line-height: 16px;
    padding: 15px 20px;
    margin: 0 20px 25px 0;

    .bookSpecs {
      font-weight: normal;
      padding-left: 50px;
    }
  }
}
