@import 'src/styles/colors.scss';
@import 'src/styles/fonts.scss';

.OrdersPlaceholderWrapper {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  background-color: $backgroundColor;
  margin: 15px;
  border-radius: 15px;
  padding-top: 100px;
  text-align: center;
  font-size: $fontTitle;
  box-shadow: 0px 0px 10px $primaryColorLighter;
}
