@import 'src/styles/colors.scss';
@import 'src/styles/fonts.scss';

.PieWrapper {
  display: grid;
  grid-template-columns: 1fr;
  background-color: $backgroundColor;
  border-radius: 15px;
  box-shadow: 0px 0px 10px $primaryColorLighter;
  padding: 5px;
  min-width: 0;
  .pieContainer {
    position: relative;
    margin: 20px 0 0;
    min-width: 0;
    .chartjs-render-monitor {
      display: flex;
    }

    .totalOrder {
      font-size: $fontSubtitle;
      font-weight: $weightBold;
      margin: 60px 0 50px 0;
      @media only screen and (max-width: 1200px) {
        margin: 5px 0 0;
      }

      .totalOrderNumber {
        display: inline-block;
        vertical-align: middle;
        font-size: $fontExtraLarge;
        line-height: 20px;
        padding-left: 10px;
        @media only screen and (max-width: 1200px) {
          font-size: $fontLink;
          padding-left: 5px;
        }
      }
    }
  }

  .orderCaptions {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 20px;
    gap: 10px;

    .orderCaption {
      font-size: $fontText;
      font-weight: $weightBold;
      white-space: nowrap;
      // margin-left: 20px;
    }

    .orderCaption:nth-child(1) {
      margin-left: 0px;
    }

    span {
      height: 25px;
      width: 25px;
      border-radius: 50%;
      display: inline-block;
      vertical-align: middle;
      margin: 0 8px 0 8px;
    }
  }
}
