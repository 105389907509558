@import 'src/styles/colors.scss';

.LogoWrapper {
  display: flex;
  align-items: center;
  // delete direction once SPINE_LOGO in working in BE
  flex-direction: column;
}

.logoText {
  font-size: 11;
  color: $subtitleColor;
}
