@import 'src/styles/colors.scss';
@import 'src/styles/fonts.scss';

.Button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  font-size: $fontText;
  text-align: center;
  cursor: pointer;
  transition: 300ms;
  will-change: transform;
  padding: 0 10px;
  max-width: 100%;
  span {
    position: relative;
    &:after {
      position: absolute;
      left: 100%;
      display: inline-block;
      animation: dotty steps(1, end) 1s infinite;
      content: '';
    }
  }
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }
  &:hover {
    transform: scale(0.98);
    text-decoration: none;
  }
  &:active {
    transform: scale(0.95);
  }
}

@keyframes dotty {
  0% {
    content: '';
  }
  25% {
    content: '.';
  }
  50% {
    content: '..';
  }
  75% {
    content: '...';
  }
  100% {
    content: '';
  }
}

button.Button.light {
  color: $primaryColor;
  background: $backgroundColor;
}

button.Button.dark {
  background: $primaryColor;
  color: $backgroundColor;
}
