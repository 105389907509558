@import 'src/styles/colors.scss';
@import 'src/styles/fonts.scss';

.initialsBadge {
  color: $backgroundColor;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 50px;
  height: 50px;
  font-size: $fontText;
  font-weight: 500;
  margin-right: 15px;
  background-color: $primaryColor;
}

.red {
  background-color: $orderCancelledColor;
}
