@import 'src/styles/colors.scss';
@import 'src/styles/fonts.scss';

.LandingPageWrapper {
  padding: 15px;

  .landingPageInnerWrapper {
    background-color: $backgroundColor;
    box-shadow: 0px 0px 10px $primaryColorLighter;
    border-radius: 15px;
    padding: 5px;
    font-size: $fontLarge;
    font-weight: $weightRegular;
    height: 400px;
    text-align: center;
    padding: 80px 0;
  }
}
