$cover-image-url: url('../../../../../../../../images/cover-image.jpg');

.MTTCover,
.landscapeCover {
  background-image: $cover-image-url;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
}

.MTTCover {
  height: 100%;
  width: 100%;
}

.landscapeCover {
  height: 70%;
  width: 80%;
}

.columnsCoverWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  inset: 20px 20px;
  gap: 10px;
  .columnsCoverInner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    height: 100%;
    gap: 10px;
    .columnsCover {
      border-radius: 3px;
      height: 100%;
      background-image: $cover-image-url;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}

.columnCoverWrapper {
  .columnsCoverInner {
    grid-template-columns: 50%;
    justify-content: center;
  }
}