@import 'src/styles/colors.scss';
@import 'src/styles/fonts.scss';

.layoutWrapper {
  width: 100%;
  .LayoutForm {
    border: 1px solid $borderColor;
    box-sizing: border-box;
    border-radius: 15px;
    padding: 13px 15px 0;
    margin-bottom: 25px;
    width: 100%;
    .sectionInputs {
      border: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 10px;
      legend {
        padding: 0;
      }
    }
    .sectionInputTitle {
      font-size: $fontText;
      font-weight: 700;
    }
  }
}

