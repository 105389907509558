@import 'src/styles/colors.scss';
@import 'src/styles/fonts.scss';

.ordersWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: center;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  overflow-x: auto;

  @supports (gap: 15px) {
    padding: 15px;
    gap: 15px;
  }
  @supports not (gap: 15px) {
    padding: 10px;
    .ordersColumn {
      margin: 10px;
    }
  }
  @media only screen and (max-width: 1200px) {
    grid-template-columns: 1fr;
  }

  .ordersDetails {
    position: relative;
    overflow: auto;
    background-color: $backgroundColor;
    border-radius: 15px;
    box-shadow: 0px 0px 10px $primaryColorLighter;
    padding: 5px;
    min-width: 0;
    .dataTitles,
    .dataRow {
      display: grid;
      margin: 0 auto;
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: minmax(50px, auto);
      border-bottom: 1px solid $borderColor;
      align-items: center;
      justify-items: flex-start;

      .orderId,
      .data1 {
        grid-column: 1/2;
      }

      .orderDate,
      .data2 {
        grid-column: 2/3;
      }

      .orderStatus,
      .data3 {
        grid-column: 3/4;
      }
    }

    .data1 {
      font-weight: $weightIntermediate;
    }

    .data3 {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      font-weight: $weightIntermediate;
      color: $backgroundColor;
      height: 30px;
      min-width: 100px;
      font-size: $fontText;
    }

    .data1,
    .data2 {
      font-size: $fontText;
      margin-left: 25px;
    }

    .dataTitles {
      font-weight: $weightBold;
      font-size: $fontSubtitle;
      line-height: 18px;
      color: $primaryColor;
      .orderId,
      .orderDate,
      .orderStatus {
        margin: 25px 20px;
      }
    }
  }
}

.cancelled {
  background-color: $orderCancelledColor;
}

.completed {
  background-color: $orderCompletedColor;
}

.confirmed {
  background-color: $orderConfirmedColor;
}

.pending {
  background-color: $orderPendingColor;
}
