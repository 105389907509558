@import 'src/styles/colors.scss';
@import 'src/styles/fonts.scss';

.TemplateWrapper {
  background-color: $backgroundColor;
  border: 1px solid $borderColor;
  border-radius: 15px;
  padding: 0 10px 10px;

  .templateDescription {
    margin: 1rem 0 1rem;
    font-weight: bold;
    font-size: $fontText;
    line-height: 16px;
  }
  
  .templatesContainer {
    display: block;
  }
}
