@import 'src/styles/colors.scss';
@import 'src/styles/fonts.scss';

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;500;600;700&display=swap');

body {
  margin: 0;
  min-height: 100vh;
  overflow: auto;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.App {
  min-height: 100vh;
}

.errorText {
  color: $warningColor;
  font-weight: $weightLight;
  font-size: $fontText;
}

button {
  padding: 0;

  cursor: pointer;
  user-select: none;

  border: 0;
  outline: none;
  background: transparent;

  appearance: none;
  text-decoration: none;

  white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;

  &::-moz-focus-inner {
    padding: 0;
    border: 0;
  }

  &:hover {
    text-decoration: underline;
  }
}