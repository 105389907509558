@import 'src/styles/colors.scss';
@import 'src/styles/fonts.scss';

.inputWrapper {
  display: grid;
  flex-direction: column;
  font-weight: $weightRegular;
  font-size: $fontText;
  font-weight: $weightBold;
  margin-bottom: 5px;

  .input {
    outline: none;
    padding: 15px;
    margin: 7px 0 17px;
    color: $lightGreyColor;
    border-radius: 15px;
    border: 1px $borderColor solid;
  }

  .errorInput {
    color: $warningColor;
    border: 1px $warningColor solid;
    border-radius: 15px;
  }

  .errorText {
    margin: -15px 0 0 10px;
  }
}
