@import 'src/styles/colors.scss';
@import 'src/styles/fonts.scss';

.signUpForm {
  font-size: $fontText;
  width: 600px;
  max-width: 100%;

  .signupRows {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 40px;
    padding-top: 40px;
    @media (max-width: 600px) {
      grid-template-columns: 1fr;
    }
  }
  footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    a {
      color: $linkColor;
      text-decoration: none;
      font-weight: $weightIntermediate;
    }
  }
}
