@import 'src/styles/colors.scss';
@import 'src/styles/fonts.scss';

.AlertContainer {
  padding: 20px;
  border-radius: 12px;
  position: fixed;
  z-index: 10;
  background-color: $backgroundColor;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 10px $primaryColorLighter;
  max-width: 350px;
  .alertClose {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    &:hover {
      color: $lightGreyColor;
    }
  }
  .alertMessage {
    color: $greenColor;
    padding: 20px 5px;
    font-size: 15px;
  }
}
