@import 'src/styles/colors.scss';
@import 'src/styles/fonts.scss';

.textPages {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 80px;
  height: 60px;
  background: $backgroundColor;
  border: 1px solid $borderColor;
  border-radius: 5px;
}

.paragraphFrame {
  min-height: 55px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 10px;
}

.columnsForText {
  padding-bottom: 2.5px;
  display: flex;
  justify-content: space-between;
  width: -webkit-fill-available;
  width: 100%;
}
