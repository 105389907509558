@import 'src/styles/colors.scss';
@import 'src/styles/fonts.scss';
$cover-image-url: url('../../../../../../images/cover-image.jpg');

$borderRadius: 5px;
$spineReplication: 1.5px solid $backgroundColor;

.chapterPageContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  max-width: 750px;
  padding: 5px;
  border-radius: $borderRadius;
  .chapterPagePreview {
    position: relative;
    aspect-ratio: 1.4142/1;
    display: flex;
    font-weight: $weightIntermediate;
    font-size: $fontSubtitle;
    color: $primaryColor;
    justify-content: space-around;
    padding: 13px;
    .chapterPreviewLandscapeWrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;

      .chapterPreviewLandscape {
        background-image: $cover-image-url;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        border-radius: $borderRadius;
      }
  
    }

    .pagePreviewLandscape {
      width: 100%;
      height: 100%;
      background-image: $cover-image-url;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: $borderRadius;
    }

    &:first-child {
      border-right: $spineReplication;
      border-top-left-radius: $borderRadius;
      border-bottom-left-radius: $borderRadius;
      display: grid;
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 1fr;
      gap: 20px;
    }

    &:last-child {
      border-left: $spineReplication;
      border-top-right-radius: $borderRadius;
      border-bottom-right-radius: $borderRadius;
    }
  }
}
