@import 'src/styles/colors.scss';
@import 'src/styles/fonts.scss';

.ColorInputWrapper {
  position: relative;
  display: grid;
  align-items: center;
  grid-template-columns: min-content 1fr min-content;
  border-radius: 15px;
  border: 1px $borderColor solid;
  margin-bottom: 10px;
  padding: 7px 15px;
  font-size: $fontText;
  font-weight: $weightBold;

  .swatch {
    width: 26px;
    height: 26px;
    border: 1px solid $borderColor;
    box-sizing: border-box;
    border-radius: 3px;
    margin-right: 15px;
  }

  .colorInput {
    display: none;
  }
}
