@import 'src/styles/colors.scss';
@import 'src/styles/fonts.scss';

.forgotPasswordForm {
  margin-top: 40px;
  font-size: $fontText;
  width: 350px;
  max-width: 100%;
  footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    a {
      color: $linkColor;
      text-decoration: none;
      font-weight: $weightIntermediate;
    }
  }
}
