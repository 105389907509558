@import 'src/styles/colors.scss';

.PreviewWrapper {
  position: absolute;
  right: 15px;
  left: 15px;
  bottom: 15px;
  top: 15px;
  overflow-x: auto;
  border-radius: 15px;
  background-color: $backgroundColor;
  box-shadow: 0px 0px 10px $primaryColorLighter;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
