$fontExtraLarge: 36px;
$fontLarge: 36px;
$fontTitle: 24px;
$fontLink: 20px;
$fontSubtitle: 15px;
$fontText: 13px;
$fontTiny: 11px;
$fontMini: 9px;
$weightBold: 700;
$weightIntermediate: 600;
$weightRegular: 500;
$weightLight: 300;
