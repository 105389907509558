@import 'src/styles/colors.scss';
@import 'src/styles/fonts.scss';

.apiKeyWrapper {
  border: solid 1px $borderColor;
  background-color: $borderColor;
  padding: 15px;
  padding-bottom: 0;
  border-radius: 12px;
  max-width: fit-content;
  line-height: 18px;
}
