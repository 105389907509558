@import 'src/styles/colors.scss';
@import 'src/styles/fonts.scss';

.innerWrapper {
  background-color: $backgroundColor;
  border-radius: 15px;
  height: 100%;
  width: 100%;
  overflow: auto;

  .header {
    padding-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    padding: 15px;

    .title {
      font-size: $fontLarge;
      font-weight: $weightBold;
      margin: 120px 0 18px;
      text-align: center;
    }

    .entry {
      font-size: $fontText;
      font-weight: $weightRegular;
      margin-bottom: 10px;
      text-align: center;
    }

    .error {
      color: $warningColor;
    }
  }

  .appWrapper {
    height: 100%;
    display: grid;
    grid-template-rows: max-content 1fr;

    .pageWrapper {
      overflow: auto;
      background-color: $borderColor;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      position: relative;
      min-width: 0;
      min-height: 0;
    }
  }
}
