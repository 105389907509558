@import 'src/styles/colors.scss';
@import 'src/styles/fonts.scss';

.navBar {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-color: $backgroundColor;
  font-weight: $weightBold;
  border-bottom: 1px solid $borderColor;
  min-width: 0;
  .navTop {
    display: flex;
    justify-content: space-between;
    padding: 20px;

    .navIcon {
      display: flex;
      align-items: center;

      .navTitle {
        font-size: $fontSubtitle;
        margin-left: 10px;
      }
    }

    .navUserInfo {
      display: flex;
      align-items: baseline;
      text-decoration: none;
      color: $primaryColor;
      position: relative;

      .navUserName {
        font-size: $fontText;
        padding-right: 5px;
      }

      .logoutModal {
        min-width: -webkit-fill-available;
        border: 0;
        border-radius: 10px;
        padding: 12px;
        background-color: $backgroundColor;
        box-shadow: 0px 0px 10px $primaryColorLighter;
        font-weight: $weightRegular;
        font-size: $fontText;
        margin-top: 8px;
        position: absolute;
        right: 0px;
        top: 40px;
        &:hover {
          cursor: pointer;
          color: $backgroundColor;
          background-color: $primaryColor;
        }
      }
      button {
        display: flex;
        align-items: center;
        font-size: $fontText;
        font-weight: $weightBold;
      }
    }
  }
  nav {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    overflow: auto;
    padding: 0 20px 20px;
    .navLink {
      padding: 0 20px 0 10px;
      font-size: $fontLink;
      text-decoration: none;
      color: rgba(0, 0, 0, 0.2);
      &.navLinkselected {
        color: $primaryColor;
      }
    }
  }
}
