@import 'src/styles/colors.scss';

.checkboxWrapper {
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &:hover input ~ .checkmark {
    background-color: $primaryColorLighter;
  }

  input:checked ~ .checkmark {
    background-color: $primaryColor;
  }
  .checkmark {
    position: relative;
    display: block;
    height: 16px;
    width: 16px;
    background-color: #eee;
    transition: 300ms;
    border-radius: 3px;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkmark:after {
    top: 3px;
    left: 6px;
    width: 3px;
    height: 7px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
