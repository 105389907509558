@import 'src/styles/colors.scss';
@import 'src/styles/fonts.scss';

.coverContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  .CoverWrapper {
    display: grid;
    grid-template-columns: 350fr 24fr 350fr;
    width: 100%;
    border-radius: 5px;
    margin: 80px 0 30px;
    max-width: 750px;

    .cover {
      position: relative;
    
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: $weightIntermediate;
      font-size: $fontSubtitle;
      color: $primaryColor;
      aspect-ratio: 1.4142/1;
      border-radius: 0px 5px 5px 0px;
      &:first-child {
        border-radius: 5px 0px 0px 5px;
      }
      .coverImage {
        position: absolute;
        inset: 20px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .spine {
      // width: 24px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      aspect-ratio: 24 / 248;
      svg {
        width: 100%;
      }
      .spineTitle {
        font-weight: bold;
        font-size: 10px;
        writing-mode: vertical-rl;
        text-orientation: mixed;
      }
    }
  }
}
